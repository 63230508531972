<template>
  <div style="height: 100%">
    <ModelList />
  </div>
</template>

<script>
export default {
  name: "Models",
  components: {
    ModelList: () => import("@/views/models/ModelList"),
  },
};
</script>

<style scoped></style>
